.contact-menu {
    width: 80vw;
    margin: 0 auto;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    margin-bottom: 16px;
    margin-top: 16px;
}


.form-group {
    flex-direction: column;
}

.form-group label {
    margin-bottom: 0;
    font-weight: normal;
    text-align: left;
    align-self: flex-start;
}

.form-group input {
    width: 100%;
    height: 24px;
    padding: 0px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 0;
}

.form-group textarea {
    width: 100%;
    height: 128px;
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 0;
    resize: none;
    overflow: hidden;
}

.form-group textarea {
    resize: vertical;
}

button {
    width: 100%;
    padding: 10px;
    background-color: #bcc5ce;
    color: rgb(214, 153, 93);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

button:hover {
    background-color: #eeb656;
}