* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: OpenSans;
  src: url(assets/OpenSans-Regular.ttf);
  font-weight: 800;
}

@font-face {
  font-family: OpenSansSemiBold;
  src: url(assets/OpenSans-SemiBold.ttf);
}

body {
  text-align: center;
  font-size: 20px;
  font-family: 'OpenSans';
}

h1, h2, h3, h4, h5 {
  font-family: 'OpenSansSemiBold';
}

