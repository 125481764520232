* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

.split-screen-right {
    display: flex;
}

.left-pane-ssr,
.right-pane {
    flex: 1;
}

.left-pane-ssr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    /* Ensures the left pane takes its share of the space */
    padding: 20px;
    /* Add any padding if needed */
}

.right-pane img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 15px;
    /* Ensures the entire image is visible and maintains its aspect ratio */
}

.right-pane {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    max-height: 100%;
    /* Ensures the text blocks do not exceed the height of the image */
    overflow-y: auto;
    /* Adds a scrollbar if the content exceeds the height */
}

.text-block {
    margin-bottom: 20px;
    text-align: left;
    align-items: center;
}

/* Media query for narrow screens */
@media (max-width: 768px) {
    .split-screen-right {
        flex-direction: column;
    }

    .left-pane-ssr,
    .right-pane {
        flex: none;
        width: 100%;
        height: auto;
    }

    .left-pane-ssr img,
    .right-pane img {
        width: 100%;
        height: auto;
    }

    .left-pane-ssr {
        order: 2;
        /* Change order to ensure text falls below the image */
    }

    .right-pane {
        order: 1;
        /* Change order to ensure image is above the text */
    }

    .text-block {
        padding: 20px;
        text-align: left;
    }
}