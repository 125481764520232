.menu {
    position: fixed;
    top: 0;
    left: 0; /* Extend from the left */
    width: 100%;
    height: 100%;
    background-color: #3C88A3 ; /* White background */
    opacity: 98%;
    color: #000; /* Black text color */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
  }

  .close-icon ul h2:hover {
    background-color: #f0f0f0;
  }

  .close-icon ul h2 {
    cursor: pointer;
  }
  
  .menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  
  .menu li {
    padding: 20px;
    cursor: pointer;
  }
  
  .menu li:hover {
    background-color: #eeb656;
  }

  .menu ul h2 {
    cursor: pointer;
  }

  .menu ul h2:hover {
    background-color: #eeb656;
  }
