.carousel {
    margin: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.carousel-grid, .row-of-three {
    display: grid;
    gap: 10px;
}

.carousel-grid {
    grid-template-columns: repeat(7, 1fr); /* 7 columns for the wide layout */
}

.row-of-three {
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;  /* Center the row */
    margin-top: 10px;
}

.carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-item img {
    max-width: 100%;
    height: auto;
    padding: 8px;
}

@media (max-width: 768px) {
    .carousel-grid, .row-of-three {
        grid-template-columns: 1fr; /* Stack items vertically */
    }
    .carousel-item {
        max-width: 150px; /* Set max width for small screens */
    }
    .row-of-three {
        justify-content: center; /* Center the row in vertical mode */
        margin-top: 0; /* Adjust margin as needed */
    }
}
