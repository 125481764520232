.container {
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
  }
  
  .left {
    width: 50%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1px;
    height: 100%; /* Ensure the grid takes the full height */
    align-items: center;
  }
  
  .right {
    width: 50%;
    padding: 32px;
    display: flex;
    align-items: left;
    justify-content: left;
    border-left: none;

  }
  
  .image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
  
  .text-container {
    text-align: center;
  }
  
  .text-left {
    text-align: left;
  }