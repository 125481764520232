.footer {
    background-color: #fff;
    color: #222;
    padding: 20px 0;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 32px;
}

.footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.footer-section {
    margin: 10px;
    text-align: center;
}

.footer-section h2 {
    margin-bottom: 10px;
}

.footer-section p,
.footer-section a {
    margin: 5px 0;
    color: #333;
    text-decoration: none;
}

.footer-section a:hover {
    text-decoration: underline;
}

.qr {
    margin: 16px;
    max-width: 100px;
    max-height: auto;
}