.split-screen {
    display: flex;
    margin: 0;
    padding-left: 0;
}

.left-pane {
    margin-top: 0px;
    flex: 1;
    width: 50%;
}
.right-pane {
    flex: 1;
    width: 50%;
}

.left-pane img {
    order: 1;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 15px;

}

.right-pane {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    /* Ensures the left pane takes its share of the space */
    padding: 20px;
    /* Add any padding if needed */
}

.text-block {
    margin-bottom: 20px;
    text-align: left;
    align-items: center;
    width: 90%;
}

/* Media query for narrow screens */
@media (max-width: 768px) {
    .split-screen {
        flex-direction: column;
    }

    

    .left-pane,
    .right-pane {
        flex: none;
        width: 100%;
        height: auto;
    }


    .left-pane {
        order: 1;
        /* Change order to ensure text falls below the image */
    }

    .right-pane {
        order: 10;
        /* Change order to ensure image is above the text */
    }

    .text-block {
        padding: 20px;
    }
}