p, ul {
    font-size: 16
}

h4 {
    font-size: 22
}

h2,
h5 {
    font-size: 24
}


p,
h2,
h3 {
    margin-bottom: 16px;
}