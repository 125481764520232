.page-break {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* Full viewport height */
    text-align: center;
}

.page-break-text {
    font-size: 3rem;
    /* Large text size */
    margin: 20px 0;
    /* Space between the text and the <hr> elements */
}

hr {
    width: 100%;
    border: none;
    border-top: 0.5px solid #121111;
    /* Customize the line style */
    margin: 0;
}