* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }

.header {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: transparent;
    color: transparent;
}

.hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-right: 20px;
}

.line {
    width: 100%;
    height: 4px;
    background-color: #333;
    transition: transform 0.3s ease;
}

.spin {
    transform: rotate(90deg);
}