.small-screen-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  margin-bottom: 32px;
  padding: 0;
  box-sizing: border-box;
}

.image-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1px; /* Add gap between images */
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 15px;
}

.grid-image {
  margin: 1px;
  width: 100%;
  height: auto;
  max-width: 100%;
  cursor: pointer;
  display: block;
  border: 1px black;
  border-radius: 15px;
}

.text-overlay {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: white; /* 98% opaque background */
  opacity: 98%;
  color: rgb(30, 29, 29);
  font-size: 18px;
  text-align: center;
  cursor: pointer;
}

@media (max-width: 768px) {
  .text-overlay {
    font-size: 16px; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .text-overlay {
    font-size: 14px; /* Further adjust font size for very small screens */
  }
}